import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { FaYoutube } from 'react-icons/fa';

import { AppWrap, MotionWrap } from '../../wrapper'
import './Youtube.scss'
import { urlFor, client } from '../../client'


const Youtube = () => {

  const [youtube, setYoutube] = useState([]);

  useEffect(() => {
    const query = '*[_type == "youtube"]';

    client.fetch(query)
    .then((data) => {
      setYoutube(data);
    });
  }, []);
  
  return (
    <>
      <h2 className='head-text'>Oh, I almost <span>forgot </span> <br /> I have some cool <span> Youtube </span>videos</h2>

      <div className='app__profiles'>
        {youtube.map((youtube, index) => (
          <div
          className='app__profile-item'
          key={youtube.title + index}
          >
            <a href={youtube.videoLink} target="_blank" rel="noreferrer">
                <motion.div 
                whileInView={{ opacity: 1 }}
                whileHover={{ scale: 1.1 }}
                transition={{ duration: 0.5, type: 'tween' }}
                className="app__youtube-img app__flex"
                >
                  <img src={urlFor(youtube.imgUrl)} alt={youtube.title} />
          
                  <motion.div
                    whileHover={{ opacity: [0, 1] }}
                    transition={{ duration: 0.25, ease: 'easeInOut', staggerChildren: 0.5 }}
                    className="app__youtube-hover app__flex"
                  >
                    <motion.div
                      whileInView={{ scale: [1, 1] }}
                      whileHover={{ scale: [1, 1] }}
                      transition={{ duration: 0.25 }}
                      className="app__flex"
                    >
                      <FaYoutube />
                    </motion.div>
                  </motion.div>
                </motion.div>
          
                <h2 className='bold-text' style={{ marginTop: 20 }}>
                  {youtube.title}
                </h2>
                <p className='p-text' style={{ marginTop: 10 }}>
                  {youtube.description}
                </p >
            </a > 
          </div>     
        ))}
      </div>
    </>
    )
}

export default AppWrap(
  MotionWrap(Youtube, 'app__youtube'), 
  'youtube',
  'app__primarybg'
);